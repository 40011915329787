import React, { useState } from "react"
import axios, { AxiosResponse } from 'axios'
import querystring from 'querystring'
import GitHubIcon from "./icons/github"
import LinkedInIcon from "./icons/linkedin"
import TwitterIcon from "./icons/twitter"

const Footer = (): JSX.Element => {

    const [email, setEmail] = useState<string>("")
    const [status, setStatus] = useState<number>(0)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        let response: AxiosResponse

        try {
            response = await axios.post('/.netlify/functions/mailchimp_subscribe', querystring.stringify({ email }))
        } catch (error) {
            setStatus(500)
            return
        }

        setStatus(response.status)
    }

    return (
        <footer className="footer">
            <div className="social-media">
                <a className="svg-anchor" target="_blank" rel="noopener noreferrer" href="https://github.com/shachar-langer"><GitHubIcon /></a>
                <a className="svg-anchor" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/shachar-langer/"><LinkedInIcon /></a>
                <a className="svg-anchor" target="_blank" rel="noopener noreferrer" href="https://twitter.com/shachar_langer"><TwitterIcon /></a>
            </div>
            <div className="newsletter">
                <p className="newsletter__title">Get updates when I do something cool:</p>
                {status === 0 && (
                    <form className="newsletter__form" onSubmit={handleSubmit}>
                        <input className="newsletter__email" name="email" type="email" placeholder="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        <button className="btn newsletter__button" type="submit">Subscribe</button>
                    </form>)}
                <p className="newsletter__result">
                    {status === 201 ? "✔ Subscribed. Please check your email for a confirmation link" :
                        status !== 0 ? "✖ Something went wrong. Please wait a few minutes and try again" : null}
                </p>
            </div>
        </footer>
    )
}

export default Footer