import { Link } from "gatsby"
import React, { useState } from "react"
import handleNavClick from "./handle-nav-click"
import HamburgerIcon from "./icons/hamburger"

const Header = ({ siteTitle = '' }: { siteTitle?: string }): JSX.Element => {
  const [toggleNavBar, setToggleNavBar] = useState<boolean>(false)

  const onClick = () => {
    setToggleNavBar(!toggleNavBar)
  }

  return (
    <header className="header">
      <h1 className="logo">
        <Link className="logo-link" to="/">
          {siteTitle}
        </Link>
      </h1>
      <button className="nav-toggle-btn" onClick={onClick}>
        <HamburgerIcon />
      </button>
      <nav className={`nav-bar ${toggleNavBar ? "active" : ""}`}>
        <ul className="nav-list">
          <li>
            <a onClick={handleNavClick("#about")}>About</a>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <a onClick={handleNavClick("#projects")}>Projects</a>
          </li>
          <li>
            <button className="btn upper" onClick={handleNavClick("#contact")}>
              Hire me
            </button>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
