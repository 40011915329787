const handleNavClick = (id: string): () => void => {
  return () => {
    // Check if the browser is IE11
    const isIE11 = !!window.MSInputMethodContext && !!document['documentMode']

    const targetDiv: HTMLDivElement = document.querySelector(id)

    // The header div is a sticky header.
    // When we scroll to a div, we need to subtract the size of the header.
    const headerDiv = document.getElementsByClassName("header")[0] as HTMLElement

    // If the target div doesn't exist, redirect to the target div in the homepage
    if (targetDiv === null) {
      window.location.href = `/${id}`
    }

    const offsetTop = targetDiv.offsetTop - headerDiv.offsetHeight

    // ie 11 does not support smooth scroll, so we will simply scroll
    if (isIE11) {
      window.scrollTo(0, offsetTop)
    } else {
      window.scroll({
        behavior: "smooth",
        left: 0,
        // top gets the distance from the top of the page of our target element
        top: offsetTop,
      })
    }
  }
}

export default handleNavClick
